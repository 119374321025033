// Global variables here
$background-color: #D9E0E7;
$info-background: #1F3C4D;
$react-color: #28B4C8;
$link-color: #3172B8;
$white: #FFFFFF;

$tiger-team: #FF6358;
$lemon-team: #FFD246;
$organic-team: #55AB1D;
$ocean-team: #28B4C8;

$border-radius: 2px;
$primary: #3f72b4;
$primary-dark: #12384C;
$secondary: #f6f6f6;
$info: #3f72b4;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$body-text: #424242;
$body-bg: #ffffff;
$headings-text: #272727;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #ffffff;
$base-text: #424242;
$base-bg: #fafafa;
$hovered-text: #424242;
$hovered-bg: #ececec;
$selected-text: #ffffff;
$selected-bg: #3f72b4;
$button-text: #424242;
$button-bg: #f5f5f5;
$link-text: #3f72b4;
$link-hover-text: #1f3c4d;
$series-a: #3f72b4;
$series-b: #ffd246;
$series-c: #78d237;
$series-d: #28b4c8;
$series-e: #ff6358;
$series-f: #aa46be;
